@media screen and (min-width: 1280px) {


    body {
        color: #222222;
        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
        margin: 0px;
        letter-spacing: -1px;
    }

    .mobile {
        display: none;
    }


    /*----------------------------------------------------------------------- 헤더 -------------------------------------*/

    .root {
        width: 100%;
        margin: 0 auto;
    }

    .header {
        width: 100%;
        background-image: url(../img/top_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        padding-bottom: 400px;
    }

    .header-container {
        width: 950px;
        margin: 0 auto;
    }

    .header-logo {
        padding-top: 100px;
        padding-bottom: 150px;
    }

    .header-txt-main {
        font-weight: 900;
        color: #fff;
        font-size: 100px;
        letter-spacing: 0px;
    }

    .header-bar {
        width: 200px;
        height: 3px;
        background-color: #fff;
        margin: 30px 0;
    }

    .header-txt-main-sub {
        font-size: 30px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 120px;
    }

    .header-contents {}

    .header-contents-box {
        width: 284px;
        height: 284px;
        border: 2px solid #fff;
        display: inline-block;
    }

    .header-contents-box1 {
        width: 284px;
        height: 284px;
        border: 2px solid #fff;
        display: inline-block;
        margin: 0 35px;
    }

    .header-contents-txt-title {
        font-size: 30px;
        font-weight: 700;
        color: #fff;
        margin-top: 60px;
        margin-left: 40px;

    }

    .header-contents-txt-sub {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        margin-top: 30px;
        margin-left: 40px;
    }


    /*----------------------------------------------------------------------- 비전 -------------------------------------*/


    .vision {
        width: 100%;
        background-image: url(../img/vision_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        margin-top: 100px;
    }

    .vision-container {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 430px;
    }

    .vision-title {
        font-family: 'Roboto';
        font-weight: 900;
        font-size: 95px;
        letter-spacing: 5px;
        text-align: center;
    }

    .vision-bar {
        width: 150px;
        height: 3px;
        background-color: #222;
        margin: 50px auto;
    }

    .vision-title-sub {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        line-height: 40px;
    }

    .vision-title-sub span {
        font-weight: 700;
    }

    .vision-contents-title {
        width: 320px;
        font-family: 'Roboto';
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        background-color: #fff;
        border-radius: 30px;
        margin: 0 auto;
        margin-top: 430px;
        padding: 10px;
    }

    .visoion-contents {
        border-top: 2px solid #888;
        border-bottom: 2px solid #888;
        text-align: center;
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .visoion-contents-box {
        display: inline-block;
        margin: 30px 0;
        padding: 0 130px;
    }

    .visoion-contents-box1 {
        display: inline-block;
        margin: 30px 0;
        border-left: 1px solid #888;
        border-right: 1px solid #888;
        padding: 0 120px;
    }

    .visoion-contents-box-ko {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }

    .visoion-contents-box-en {
        font-size: 17px;
        font-weight: 400;
        color: #888;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 10px;
    }

    .vision-contents-sub-box {
        text-align: center;
    }

    .vision-contents-sub-box-txt {
        display: inline-block;
        vertical-align: top;
        font-size: 17px;
        text-align: left;
        line-height: 40px;
        margin: 0;
    }

    .vision-contents-sub-box-txt1 {
        display: inline-block;
        vertical-align: top;
        font-size: 17px;
        text-align: left;
        line-height: 40px;
        margin: 0 130px;
    }

    .vision-contents-white-box {
        width: 1200px;
        padding: 20px 0;
        background-color: #fff;
        text-align: center;
        margin-top: 30px;
    }

    .vision-contents-white-box-txt {
        display: inline-block;
        font-size: 20px;
    }

    .vision-contents-white-box-txt-2 {
        display: inline-block;
        margin: 0 115px;
        color: #d1d1d1;
    }


    /*----------------------------------------------------------------------- 플랫폼 -------------------------------------*/


    .platform {
        width: 100%;
        background-image: url(../img/platform_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        margin-top: 10px;
    }

    .platform-contents {
        width: 1100px;
        margin: 0 auto;
        padding-top: 100px;
        padding-bottom: 250px;
    }

    .platform-contents-txt {
        display: inline-block;
        margin-top: 10px;
    }

    .platform-contents-txt-box {
        width: 300px;
        border: 1px solid #222;
        padding: 5px;
    }

    .platform-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        margin: 0 15px;
        padding-right: 15px;
    }

    .platform-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
    }

    .platform-contents-txt-title {
        font-size: 60px;
        font-weight: 700;
        line-height: 75px;
        letter-spacing: -2px;
        margin: 40px 0;
    }

    .platform-contents-txt-day {
        font-size: 17px;
        color: #888;
        margin-top: -20px;
        margin-bottom: 40px;
    }

    .platform-contents-txt-sub {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
    }

    .platform-contents-img {
        display: inline-block;
        float: right;
    }

    .platform-contents-img img {
        height: 700px;
    }


    /*----------------------------------------------------------------------- 파트너쉽 -------------------------------------*/


    .partnership {
        width: 100%;
        background-image: url(../img/partnership_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .partnership-contents {
        width: 1100px;
        margin: 0 auto;
        padding: 120px 0;
    }

    .partnership-contents-img {
        display: inline-block;
    }

    .partnership-contents-img img {
        height: 600px;
    }

    .partnership-contents-txt {
        display: inline-block;
        vertical-align: top;
        margin-left: 200px;
        margin-top: 80px;
    }

    .partnership-contents-txt-box {
        width: 340px;
        border: 1px solid #222;
        padding: 5px;
    }

    .partnership-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        margin: 0 15px;
        padding-right: 15px;
    }

    .partnership-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
    }

    .partnership-contents-txt-title {
        font-size: 60px;
        font-weight: 700;
        line-height: 75px;
        letter-spacing: -2px;
        margin: 40px 0;
    }

    .partnership-contents-txt-sub {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
    }


    /*----------------------------------------------------------------------- 아웃소싱 -------------------------------------*/


    .outsourcing {
        width: 100%;
        background-image: url(../img/out_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .outsourcing-contents {
        width: 1100px;
        margin: 0 auto;
        padding: 200px 0;
    }

    .outsourcing-contents-txt {
        text-align: center;
    }

    .outsourcing-contents-txt-box {
        width: 280px;
        border: 1px solid #222;
        padding: 5px;
        margin: 0 auto;
    }

    .outsourcing-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        margin: 0 15px;
        padding-right: 15px;
    }

    .outsourcing-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
        padding-right: 10px;
    }

    .outsourcing-contents-txt-title {
        font-size: 60px;
        font-weight: 700;
        line-height: 75px;
        letter-spacing: -2px;
        margin: 40px 0;
    }

    .outsourcing-contents-txt-sub {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        padding-bottom: 100px;
    }

    .outsourcing-contents-img img {
        width: 1100px;
    }


    /*----------------------------------------------------------------------- 히스토리 -------------------------------------*/


    .history {
        padding: 200px 0;
    }

    .history-title {
        font-weight: 900;
        font-size: 95px;
        letter-spacing: 5px;
        border-bottom: 2px solid #222;
        width: 1510px;
        float: right;
    }

    .history-contents {
        width: 1100px;
        margin: 0 auto;
    }

    .history-contents-namebox {
        display: inline-block;
    }

    .history-contents-namebox-wrap {
        text-align: right;
        margin-left: 340px;
    }

    .history-contents-namebox-wrap-day {
        font-weight: 400;
        font-size: 17px;
        color: #888;
        margin-top: 40px;
    }

    .history-contents-namebox-wrap-txt {
        font-size: 20px;
    }


    .history-contents-namebox-wrap-b {
        font-weight: 700;
        text-align: right;
        margin-left: 200px;
    }

    .history-contents-dotbox {
        display: inline-block;
        margin: 0 100px;
        vertical-align: top;
        margin-left: 30px;
    }

    .history-contents-dotbox-bar {
        display: inline-block;
        height: 600px;
        width: 1px;
        background-color: #888;
    }

    .history-contens-dotbox-img {
        margin: 65px 0;
        margin-left: -24px;
    }

    .history-contents-subbox {
        display: inline-block;
        vertical-align: top;
        margin-top: 30px;
        margin-left: -70px;
    }

    .history-contents-subbox-txt {
        font-size: 17px;
        line-height: 30px;
        margin-top: 30px;
    }

    .history-contents-subbox-txt-b {
        font-size: 17px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 30px;
    }


    /*----------------------------------------------------------------------- 컨택트 -------------------------------------*/


    .contactus {
        width: 100%;
        background-image: url(../img/contact_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 300px;
        padding-bottom: 100px;
    }

    .contactus-title {
        font-weight: 900;
        font-size: 95px;
        letter-spacing: 5px;
        text-align: center;
    }

    .contactus-title-bar {
        width: 150px;
        height: 3px;
        background-color: #222;
        margin: 50px auto;
        margin-bottom: 100px;
    }

    .contactus-box {
        width: 1000px;
        margin: 0 auto;
        text-align: center;
    }

    .contactus-box-left {
        display: inline-block;
    }

    .contactus-box-right {
        display: inline-block;
        vertical-align: top;
        margin-left: 100px;
    }

    .contactus-form {
        text-align: left;
    }

    .contactus-input {
        width: 400px;
        height: 50px;
        border: none;
        border-bottom: 1px solid #888;
        margin-top: 20px;
        text-indent: 15px;
        font-size: 14px;
        font-weight: 200;
    }

    .contactus-textarea {
        width: 400px;
        height: 238px;
        border: none;
        border-bottom: 1px solid #888;
        margin-top: 20px;
        resize: none;
        padding: 15px;
        font-size: 14px;
        font-weight: 200;
    }

    .contactus-btn {
        width: 1000px;
        margin: 0 auto;
    }

    .contactus-btn-box {
        text-align: center;
        margin: 50px 0;
    }

    .contactus-inputbtn {
        width: 120px;
        height: 40px;
        color: #333;
        border: 1px solid #888;
        font-weight: 700;
        font-size: 17px;
        cursor: pointer;
    }


    /*----------------------------------------------------------------------- 위치 -------------------------------------*/


    .location {
        width: 1100px;
        margin: 0 auto;
        margin-top: 200px;
    }

    .location-box {
        display: inline-block;
    }

    .location-title {
        margin-bottom: 30px;
        margin-left: 20px;
    }

    .location-title-ko {
        font-size: 70px;
        font-weight: 700;
        display: inline-block;
        padding-top: 50px;
    }

    .location-title-en {
        font-size: 45px;
        display: inline-block;
        margin-left: 10px;
    }

    .location-bar {
        width: 440px;
        height: 1px;
        background-color: #888;
    }

    .location-txt {
        margin: 50px 0;
    }

    .location-txt-box {
        margin: 30px 20px;
    }

    .location-txt-box-name {
        font-size: 20px;
        font-weight: 500;
        display: inline-block;
        width: 80px;
        border-right: 2px solid #222;
        margin: 0 30px;
    }

    .location-txt-box-sub {
        display: inline-block;
        font-size: 20px;
        vertical-align: top;
    }


    .location-txt-box-sub-01 {
        font-size: 18px;
        line-height: 25px;

    }

    .location-txt-box-sub-02 {
        font-size: 14px;
        font-weight: 400;
        color: #888;
    }

    .location-img {
        display: inline-block;
        vertical-align: top;
    }

    .location-img img {
        width: 650px;
    }


    /*----------------------------------------------------------------------- 푸터 -------------------------------------*/


    .footer {
        width: 100%;
        background-color: #222;
        margin-top: -17px;
        padding: 100px 0;
    }

    .logo-img {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
    }

    .logo-img img {
        width: 300px;
    }

    .footer-txt-01 {
        color: #fff;
        text-align: center;
        margin: 10px 0;
        font-size: 20px;
    }

    .footer-txt-01 span {
        font-weight: 500;
    }

    .footer-txt-02 {
        color: #fff;
        text-align: center;
        font-size: 17px;

    }
}