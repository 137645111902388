@media screen and (max-width: 1279px) {


    body {
        color: #222222;
        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
        margin: 0px;
        letter-spacing: -1px;
    }

    .desk {
        display: none;
    }


    /*----------------------------------------------------------------------- 헤더 -------------------------------------*/

    .root {
        margin: 0 auto;
    }

    .header {
        width: 100%;
        background-image: url(../img/top_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .header-container {
        width: 360px;
        margin: 0 auto;
        text-align: center;
    }

    .header-logo {
        padding-top: 80px;
        padding-bottom: 50px;
    }

    .header-logo img {
        width: 40%;
    }

    .header-txt-main {
        font-weight: 900;
        color: #fff;
        font-size: 50px;
        letter-spacing: 0px;
    }

    .header-bar {
        width: 200px;
        height: 3px;
        background-color: #fff;
        margin: 30px auto;

    }

    .header-txt-main-sub {
        font-size: 25px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 50px;
    }

    .header-contents {
        padding-bottom: 300px;
    }

    .header-contents-box {
        width: 190px;
        height: 190px;
        border: 2px solid #fff;
        display: inline-block;
    }

    .header-contents-box1 {
        width: 190px;
        height: 190px;
        border: 2px solid #fff;
        display: inline-block;
        margin: 10px 35px;
    }

    .header-contents-txt-title {
        font-size: 25px;
        font-weight: 700;
        color: #fff;
        margin-top: 20px;
    }


    .header-contents-txt-title1 {
        font-size: 25px;
        font-weight: 700;
        color: #fff;
        margin-top: 35px;

    }

    .header-contents-txt-sub {
        font-size: 17px;
        font-weight: 400;
        color: #fff;
        margin-top: 10px;
    }


    /*----------------------------------------------------------------------- 비전 -------------------------------------*/


    .vision {
        width: 100%;
        background-image: url(../img/vision_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .vision-container {
        width: 360px;
        margin: 0 auto;
        padding-bottom: 430px;
    }

    .vision-title {
        font-family: 'Roboto';
        font-weight: 900;
        font-size: 70px;
        letter-spacing: 5px;
        text-align: center;
    }

    .vision-bar {
        width: 150px;
        height: 3px;
        background-color: #222;
        margin: 50px auto;
    }

    .vision-title-sub {
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        line-height: 40px;
    }

    .vision-title-sub span {
        font-weight: 700;
    }

    .vision-contents-title {
        width: 300px;
        font-family: 'Roboto';
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        background-color: #fff;
        border-radius: 30px;
        margin: 0 auto;
        margin-top: 140px;
        padding: 10px;
    }

    .visoion-contents {
        border-top: 2px solid #888;
        border-bottom: 2px solid #888;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .visoion-contents-box {
        margin: 20px 0;
        padding: 0 10px;
    }

    .visoion-contents-box1 {
        margin: 10px 0;
        border-top: 1px solid #888;
        border-bottom: 1px solid #888;
        padding-top: 20px;
    }

    .visoion-contents-box-ko {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }

    .visoion-contents-box-en {
        font-size: 17px;
        font-weight: 400;
        color: #888;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 5px;
    }

    .vision-contents-sub-box-txt {
        text-align: center;
        font-size: 16px;
        text-align: left;
        line-height: 30px;
        margin-top: 15px;
    }

    .vision-contents-white-box-txt {
        font-size: 16px;
        width: 170px;
        padding: 10px 0;
        background-color: #fff;
        text-align: center;
        margin-top: 10px;
        display: inline-block;

    }

    .vision-contents-white-box-txt1 {
        font-size: 16px;
        width: 170px;
        padding: 10px 0;
        background-color: #fff;
        text-align: center;
        margin-top: 10px;
        margin-right: 16px;
        display: inline-block;

    }


    /*----------------------------------------------------------------------- 플랫폼 -------------------------------------*/


    .platform {
        width: 100%;
        background-image: url(../img/platform_bg-m.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .platform-contents {
        width: 360px;
        margin: 0 auto;
        margin-top: -100px;
        text-align: center;
    }

    .platform-contents-txt {
        margin-top: 10px;
    }

    .platform-contents-txt-box {
        width: 300px;
        border: 1px solid #222;
        padding: 5px;
        margin: 0 auto;
    }

    .platform-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        padding-right: 20px;
    }

    .platform-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
        margin-left: 15px;
    }

    .platform-contents-txt-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        letter-spacing: -3px;
        margin-top: 40px;
    }

    .platform-contents-txt-day {
        font-size: 17px;
        color: #888;
        margin-bottom: 40px;
    }

    .platform-contents-txt-sub {
        font-size: 17px;
        font-weight: 500;
        line-height: 30px;
    }

    .platform-contents-img {
        display: inline-block;
    }

    .platform-contents-img img {
        height: 320px;
        margin-top: 80px;
    }


    /*----------------------------------------------------------------------- 파트너쉽 -------------------------------------*/


    .partnership {
        width: 100%;
        background-image: url(../img/partnership_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        padding-bottom: 100px;
    }

    .partnership-contents {
        width: 360px;
        margin: 0 auto;
        padding-top: 60px;
        text-align: center;
    }

    .partnership-contents-img {
        margin-top: 80px;
    }

    .partnership-contents-img img {
        height: 320px;
    }

    .partnership-contents-txt {
        display: inline-block;
        margin-top: 80px;
    }

    .partnership-contents-txt-box {
        width: 300px;
        border: 1px solid #222;
        padding: 5px 0;
        margin: 0 auto;
    }

    .partnership-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        padding-right: 10px;
    }

    .partnership-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
        margin-left: 5px;
    }

    .partnership-contents-txt-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        letter-spacing: -3px;
        margin: 40px 0;
    }

    .partnership-contents-txt-sub {
        font-size: 17px;
        font-weight: 500;
        line-height: 30px;
    }


    /*----------------------------------------------------------------------- 아웃소싱 -------------------------------------*/


    .outsourcing {
        width: 100%;
        background-image: url(../img/out_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
    }

    .outsourcing-contents {
        width: 360px;
        margin: 0 auto;
        padding: 150px 0;
    }

    .outsourcing-contents-txt {
        text-align: center;
    }

    .outsourcing-contents-txt-box {
        width: 280px;
        border: 1px solid #222;
        padding: 5px;
        margin: 0 auto;
    }

    .outsourcing-contents-txt-box-ko {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #888;
        margin: 0 15px;
        padding-right: 15px;
    }

    .outsourcing-contents-txt-box-en {
        display: inline-block;
        font-size: 20px;
        padding-right: 10px;
    }

    .outsourcing-contents-txt-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        letter-spacing: -3px;
        margin: 40px 0;
    }

    .outsourcing-contents-txt-sub {
        font-size: 17px;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 80px;
    }

    .outsourcing-contents-img {
        text-align: center;
    }

    .outsourcing-contents-img img {
        width: 230px;
    }


    /*----------------------------------------------------------------------- 히스토리 -------------------------------------*/


    .history {
        padding: 100px 0;
    }

    .history-title {
        font-weight: 900;
        font-size: 55px;
        letter-spacing: 5px;
        border-bottom: 2px solid #222;
        padding-bottom: 20px;
        padding-bottom: 20px;
        text-align: center;
    }

    .history-contents {
        width: 360px;
        margin: 0 auto;
    }

    .history-contents-namebox {
        display: inline-block;
    }

    .history-contents-namebox-wrap {
        text-align: left;
    }

    .history-contents-namebox-wrap-day {
        font-weight: 400;
        font-size: 14px;
        color: #888;
        margin-top: 30px;
    }

    .history-contents-namebox-wrap-txt {
        font-size: 17px;
    }


    .history-contents-namebox-wrap-b {
        font-weight: 700;
        text-align: left;
    }

    .history-contents-dotbox {
        display: inline-block;
        margin: 0 50px;
        vertical-align: top;
        margin-left: 30px;
    }

    .history-contents-dotbox-bar {
        display: inline-block;
        height: 980px;
        width: 1px;
        background-color: #888;
    }

    .history-contens-dotbox-img {
        margin-top: 50px;
        margin-bottom: 130px;
        margin-left: -10px;
    }

    .history-contens-dotbox-img img {
        width: 20px;
    }

    .history-contents-subbox {
        display: inline-block;
        vertical-align: top;

    }

    .history-contents-subbox-txt {
        font-size: 14px;
        line-height: 25px;
        margin-top: 10px;
    }

    .history-contents-subbox-txt-b {
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 10px;
    }


    /*----------------------------------------------------------------------- 컨택트 -------------------------------------*/


    .contactus {
        width: 100%;
        background-image: url(../img/contact_bg.png);
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 250px;
        padding-bottom: 100px;
    }

    .contactus-title {
        font-weight: 900;
        font-size: 55px;
        letter-spacing: 5px;
        text-align: center;
    }

    .contactus-title-bar {
        width: 150px;
        height: 3px;
        background-color: #222;
        margin: 50px auto;
        margin-bottom: 100px;
    }

    .contactus-box {
        width: 360px;
        margin: 0 auto;
        text-align: center;
    }

    .contactus-box-left {}

    .contactus-form {
    }

    .contactus-input {
        width: 300px;
        height: 50px;
        border: none;
        border-bottom: 1px solid #888;
        text-indent: 15px;
        font-size: 14px;
        font-weight: 200;
    }


    .contactus-textarea {
        width: 300px;
        height: 120px;
        border: none;
        border-bottom: 1px solid #888;
        resize: none;
        padding: 15px;
        font-size: 14px;
        font-weight: 200;
    }

    .contactus-btn {
        margin: 0 auto;
    }

    .contactus-btn-box {
        text-align: center;
        margin: 50px 0;
    }

    .contactus-inputbtn {
        width: 120px;
        height: 40px;
        color: #333;
        border: 1px solid #888;
        font-weight: 700;
        font-size: 17px;
        cursor: pointer;
    }


    /*----------------------------------------------------------------------- 위치 -------------------------------------*/


    .location {
        margin-bottom: 100px;
    }

    .location-box {
        margin: 0 auto;
    }

    .location-title {
        margin-bottom: 30px;
        text-align: center;
    }

    .location-title-ko {
        font-size: 40px;
        font-weight: 700;
        display: inline-block;
        padding-top: 50px;
    }

    .location-title-en {
        font-size: 30px;
        display: inline-block;
        margin-left: 10px;
    }

    .location-bar {
        width: 300px;
        height: 1px;
        background-color: #888;
        text-align: center;
        margin: 0 auto;
    }

    .location-txt {
        width: 300px;
        margin: 0 auto;
    }

    .location-txt-box {
        margin: 30px 0;
    }

    .location-txt-box-name {
        font-size: 17px;
        font-weight: 500;
        display: inline-block;
        border-right: 2px solid #222;
        width: 50px;
        margin: 0 25px;
        padding-right: 10px;
    }

    .location-txt-box-sub {
        display: inline-block;
        font-size: 17px;
        vertical-align: top;
    }

    .location-txt-box-sub-01 {
        font-size: 16px;
        line-height: 25px;
    }

    .location-txt-box-sub-02 {
        font-size: 14px;
        font-weight: 400;
        color: #888;
    }


    /*----------------------------------------------------------------------- 푸터 -------------------------------------*/


    .footer {
        width: 100%;
        background-color: #222;
        margin-top: -17px;
        padding: 100px 0;
    }

    .logo-img {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
    }

    .logo-img img {
        width: 150px;
    }

    .footer-txt-01 {
        color: #fff;
        text-align: center;
        margin: 10px 0;
        font-size: 17px;
    }

    .footer-txt-01 span {
        font-weight: 500;
    }

    .footer-txt-02 {
        color: #fff;
        text-align: center;
        font-size: 14px;

    }
}